import http from "@/http";
import {getUrl} from "@/components/composables/functions";
import {endLoading, endLoadTable} from "@/components/composables/spinners";
import { nextTick } from 'vue';

export const state = {
    listAll: {},
    listCards: {},
    listMessages: [],
    showSpinner: false,
    cards: {
        value_total_sales: 0,
        total_sales: 0,
        total_mentorings: 0,
        total_mentees: 0,
    },
    auth: {
        user: null,
    }
}

export const mutations = {

    setListAll(state, newValue) {
        state.listAll = newValue;
    },

    setCards(state, newValue) {
        state.cards = newValue;
    },

    setMessages(state, messages) {
        state.listMessages = messages;
    },

    addMessage(state, message) {
        const index = state.listMessages.findIndex(chat => chat.mentee_id === message.mentee_id);
        if (index !== -1) {
            if (message.target === 'mentor' && message.mentor_id === state.auth.user?.id) {
                state.listMessages[index] = {
                    ...message,
                    unread_count: (state.listMessages[index].unread_count || 0) + 1
                };
            } else {
                state.listMessages[index] = message;
            }
        } else {
            state.listMessages.push(message);
        }
    },

    markAsRead(state) {
        state.listMessages = state.listMessages.map(chat => {
            if (chat.target === 'mentor' && chat.mentor_id === state.auth.user?.id) {
                return {
                    ...chat,
                    unread_count: 0,
                    read_at: new Date().toISOString(),
                    messages: chat.messages?.map(message => ({
                        ...message,
                        read_at: new Date().toISOString()
                    }))
                };
            }
            return chat;
        });
    },

    setList(state, newValue) {
        const page = newValue.page || 0;
        const limit = document.getElementById('limitFilter') ? document.getElementById('limitFilter').value : 0;
        const count = newValue.count || 0;
        newValue.start = count > 0 ? page * limit + 1 : 0;
        newValue.partial = count <= (page + 1) * limit ? count : (page + 1) * limit;

        state.listAll = newValue

        let after = state.listCards.data || {};
        after = Object.values(after);
        let arr = [];

        for (let i = 0; i < Object.keys(after).length; i++) {
            arr.push(after[i]);
        }

        const newArr = newValue.data || [];

        for (let i = 0; i < newArr.length; i++) {
            arr.push(newArr[i]);
        }

        state.listCards = {
            page,
            limit,
            count,
            partial: count <= (page + 1) * limit ? count : (page + 1) * limit,
            data: arr
        };
    },

    resetValues(state, newValue) {
        state.listAll = newValue
        state.listCards = newValue
    },

    setSpinner(state, newValue) {
        state.showSpinner = newValue
    },
}

export const actions = {
    // eslint-disable-next-line no-unused-vars
    init({state, dispatch}) {
        dispatch('validate')
    },

    setCards({commit}) {
        http.get('mentors/dashboard')
            .then(response => {
                commit('setCards', response.data);
            })
    },

    getApi({commit}, url) {
        http.get(url, {
                headers: {'Authorization': ` Bearer ${localStorage.getItem('token')} `}
            })
            .then(response => {
                commit('setList', response.data);
                endLoadTable();
                if (document.getElementById('spinnerTable')) document.getElementById('spinnerTable').style.display = 'none';
                if (document.getElementById('spinnerCard')) document.getElementById('spinnerCard').style.display = 'none';
            })
            .catch(error => {
                endLoadTable();
                console.error(error);
                // Forbidden(error);
            })
    },

    async insert({dispatch}, data) {
        let url = data.url;
        let formData = data.formData;
        if (formData.id > 0) {
            url += `/${formData.id}`;
            formData._method = 'PUT';
            delete formData.password;
        }
        return await http.post(url, formData, {
                headers: {'Authorization': ` Bearer ${localStorage.getItem('token')} `}
            })
            .then(() => {

                dispatch('getApi', getUrl(data.session));
                if (document.getElementById('spinnerCard')) document.getElementById('spinnerCard').style.display = 'none';
                return true;
            })
            .catch(error => {
                if (typeof error.response.data === "object") {
                    for (let item in error.response.data) {
                        console.log(item);
                        // notifyError(error.response.data[item][0]);
                    }
                } else {
                    // Forbidden(error);
                }
                endLoading('form', 'save');
                console.error(error);
                return false;
            })
    },

    async delete({commit, dispatch}, data) {
        const session = JSON.parse(localStorage.getItem(data.session));
        const id = data.id;
        const url = `${session.url}/${id}`;
        return await http.delete(url, {
                headers: {'Authorization': ` Bearer ${localStorage.getItem('token')} `}
            })
            .then(() => {
                // pegando os valores com js
                let start = document.getElementById('start').innerText;
                let partial = document.getElementById('partial').innerText;
                let count = document.getElementById('count').innerText;

                --partial;
                --count;

                // pegando os valores da store
                let newValues = state.listAll;

                // Se tive o valor total for menor que o inicio da lista,
                // diminuir o page e fazer nova busca
                if (partial === count && start > count) {
                    const newSession = session.params.page = newValues.page;
                    localStorage.setItem(session, newSession);
                    dispatch('getApi', session.url);
                    // se acabarem os itens desta página mas existirem mais páginas a frente,
                    //     fazer nova busca na mesma page
                } else if (partial < start && start < count) {
                    dispatch('getApi', session.url);
                    // se o total for 0, esvazia o objeto data para aparecer mensagem na tabela
                } else if (count === 0) {
                    newValues.data = {};
                    commit('resetValues', newValues);
                    --start;
                    document.getElementById('start').innerText = start;
                }

                document.getElementById('partial').innerText = partial;
                document.getElementById('count').innerText = count;
                document.getElementById('line' + id).style.display = 'none';
            })
            .catch(error => {
                console.error(error);
                // Forbidden(error);
            })
    },

    async getMessages(menteeId) {
        try {
            const response = await http.get(`mentor/get-messages/${menteeId}?page=${this.page}`);
            if (this.page === 1) {
                this.mentee = response.data.mentee;
                const newValue = response.data.messages;
                newValue.data.sort((a, b) => a.id - b.id);
                this.messages = newValue;
                await this.scrollToBottom();
            } else {
                const container = document.getElementById('chat');
                const scrollHeightBefore = container.scrollHeight;

                response.data.messages.data.sort((a, b) => a.id - b.id);
                this.messages.data = [
                    ...response.data.messages.data,
                    ...this.messages.data,
                ];

                await nextTick();
                const scrollHeightAfter = container.scrollHeight;
                container.scrollTop = scrollHeightAfter - scrollHeightBefore;
            }
            this.load = true;
        } catch (error) {
            console.error('Error fetching messages:', error);
            if (error.response?.status === 429) { // Too Many Requests
                // Aguarda 2 segundos antes de tentar novamente
                await new Promise(resolve => setTimeout(resolve, 2000));
                return this.getMessages(menteeId);
            }
            this.load = true;
        }
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters: {
        unreadMessagesCount: (state, getters, rootState) => {
            if (!Array.isArray(state.listMessages)) return 0;
            return state.listMessages.filter(chat => 
                chat.target === 'mentor' && 
                chat.unread_count > 0 && 
                chat.mentor_id === rootState.auth.user?.id
            ).length;
        }
    }
}
